<template>
  <DefaultLayout>
    <div class="bsat__container">
      <div class="bsat__tabs mt-8 mt-sm-8">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          slider-color="dark"
          color="dark"
          show-arrows
          center-active
        >
          <v-menu
            open-on-hover
            bottom
            offset-y
            v-for="(item, i) in items"
            :key="i"
          >
            <template #activator="{ on }">
              <v-tab
                class="mr-4 mr-sm-8"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'min-width: 90px'
                    : 'min-width: 160px'
                "
                v-on="on"
                @click.prevent="tabClick(i)"
              >
                {{ $t(item.tab) }}
              </v-tab>
            </template>
            <v-list class="py-0" v-if="item.steps">
              <v-list-item
                v-for="(step, index) in item.steps"
                :key="index"
                @click="goTo(step.link)"
                style="background-color: #fcfff5"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>{{ step.name }}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">{{
                    step.subtitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <v-card flat color="off_white">
              <v-card-text class="px-0">
                <keep-alive>
                  <component :is="item.component">
                    <template #img v-if="item.tab === 'about'">
                      <v-img
                        contain
                        height="550px"
                        src="@/assets/icons/New.png"
                      ></v-img>
                    </template>
                  </component>
                </keep-alive>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default";
import { EventBus } from "@/utils/EventBus";
import { getLangs } from "@/utils/helpers";

export default {
  name: "New",
  metaInfo: {
    title: "New Bioeconomy Strategy"
  },
  components: {
    DefaultLayout,
    About: () => import("@/components/new/About"),
    Phase1: () => import("@/components/new/Phase1/Phase1"),
    Phase2: () => import("@/components/new/Phase2/Phase2"),
    Phase3: () => import("@/components/new/Phase3/Phase3"),
    Phase4: () => import("@/components/new/Phase4/Phase4")
  },
  data() {
    return {
      tab: 0,
      step: 1,
      items: [
        { tab: "about", component: "About" },
        {
          tab: "phase1",
          component: "Phase1",
          steps: [
            {
              name: "Step 1",
              subtitle: "Preliminary characterisation of the region",
              link: "/new?phase=1&step=1"
            },
            {
              name: "Step 2",
              subtitle: "Assessment of the Bioeconomy status of the region",
              link: "/new?phase=1&step=2"
            },
            {
              name: "Step 3",
              subtitle: "Complementary analysis with other existing tools",
              link: "/new?phase=1&step=3"
            },
            {
              name: "Step 4",
              subtitle: "SWOT analysis",
              link: "/new?phase=1&step=4"
            }
          ]
        },
        {
          tab: "phase2",
          component: "Phase2"
        },
        {
          tab: "phase3",
          component: "Phase3",
          steps: [
            {
              name: "Step 1",
              subtitle:
                "Define the strategy development plan between RBH and regional policy makers",
              link: "/new?phase=3&step=1"
            },
            {
              name: "Step 2",
              subtitle:
                "Determine the priority areas and their corresponding main goals",
              link: "/new?phase=3&step=2"
            },
            {
              name: "Step 3",
              subtitle:
                "Identify available enabling mechanisms and resources for the bioeconomy strategy",
              link: "/new?phase=3&step=3"
            },
            {
              name: "Step 4",
              subtitle:
                "Develop supporting policy, financial mechanisms and resources to the defined priority areas",
              link: "/new?phase=3&step=4"
            },
            {
              name: "Step 5",
              subtitle: "Draft the strategy document",
              link: "/new?phase=3&step=5"
            }
          ]
        },
        {
          tab: "phase4",
          component: "Phase4",
          steps: [
            {
              name: "Step 1",
              subtitle:
                "Derive specific goals within priority areas, specific actions and responsible for implementation",
              link: "/new?phase=4&step=1"
            },
            {
              name: "Step 2",
              subtitle:
                "Assign specific policy, financial mechanisms, and resources to the defined specific actions",
              link: "/new?phase=4&step=2"
            },
            {
              name: "Step 3",
              subtitle: "Draft roadmap of the regional bioeconomy strategy",
              link: "/new?phase=4&step=3"
            },
            {
              name: "Step 4",
              subtitle: "Final regional bioeconomy strategy and final roadmap",
              link: "/new?phase=4&step=4"
            }
          ]
        }
      ]
    };
  },
  watch: {
    async tab(val) {
      const res = await this.$router
        .push({
          path: "/new",
          query: { phase: val, step: this.step }
        })
        .catch(() => {});
      this.$store.commit("SET_LANGUAGES", getLangs(res));
    },
    step(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.phase) {
      this.tab = Number(query.phase);
    }

    if (query.step) {
      this.step = Number(query.step);
    }
    this.$store.commit("SET_LANGUAGES", getLangs(this.$route));
    EventBus.$on("goToTab", tabNumber => {
      this.tab = tabNumber;
    });
  },
  beforeDestroy() {
    EventBus.$off("goToTab");
  },
  methods: {
    tabClick(i) {
      if (window) {
        window.location = `/new?phase=${i}`;
      }
    },
    goTo(link) {
      if (window) {
        this.$router.push(link);
        window.location = link;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/fonts";

.bsat__tabs {
  .v-tab {
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
  }
}
</style>
